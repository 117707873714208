import React, { useContext,useState ,useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { sidebarData } from "../constraints/constants";
import { BiLogOutCircle } from "react-icons/bi";
import { AiOutlineGlobal } from "react-icons/ai";
import AuthContext from "../context/AuthContext";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import {
  DepositWithrawData,
  DepositWithrawDataArray,
} from "../Utils/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import FlagIcon from "../assets/imagesNew/india-icon.png";
import FlagIconBn from "../assets/imagesNew/bangladesh-flag-round-circle-icon.png";
import PromotionsIcon from "../assets/images/promotion.svg";
import iconReferral from "../assets/images/referAndEarn.svg";
import iconFaq from "../assets/images/faq.svg";
import iconAffiliate from "../assets/images/agentAff.svg";
import iconDownloadApp from "../assets/images/downloadApp.svg";
import logo from "../assets/images/logo.png";
import { BsList } from "react-icons/bs";


const MenuSidebarNew = () => {

  let { logoutUser, setShowSidebar,lang ,user, setEn, setBr,launchEGTCasino,
    launchCasino,withoutLogin} = useContext(AuthContext);
  const navigate = useNavigate();
  const setFunc = (type) => {
    setData(
      DepositWithrawDataArray?.find((res) => {
        {
          return res?.key == type;
        }
      })
    );
    setKey(type);
  };
  const [data, setData] = useState({});
  const [activeClass, SetActiveClass] = useState("");
  const [key, setKey] = useState("");
  useEffect(() => {
    // setData(
    //   DepositWithrawDataArray?.find((res) => {
    //     {
    //       return res?.key == "sports";
    //     }
    //   })
    // );

    window.addEventListener('scroll', () => {
      
      if(window.scrollY > 180){
       
        SetActiveClass('gamefixed');
      }else{
        SetActiveClass('');
      }
      
   });

  }, []);
  const {t} = useTranslation()
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowSidebar(false);
      }}
    >
      <div>
        <div className="sidebar-wrapper">

          <div className="top-sidebar mb-3 d-flex justify-content-between align-items-center">
          
            <img
            onClick={() => navigate('/')}
            style={{
              height: "30px",
            }}
            src={logo}
            alt=""
          />
          
          <button
                className="bg-transparent border-0 text-white"
                onClick={() => setShowSidebar(false)}
              >
                <BsList className="fs-4" />
              </button>
          </div>
          <hr role="separator" aria-orientation="horizontal" class=" v-divider theme--light"></hr>
           <ul>
        <li>
          <figure>
            <img
              src={PromotionsIcon}
              alt=""
           
            />
          </figure>
          <Link to="/promotions" className="text-decoration-none"> <span> Promotions </span></Link>
         
          </li>
          <li>
          <figure>
            <img
              src={iconReferral}
              alt=""
           
            />
          </figure>
          <Link to="/refer" className="text-decoration-none"> <span>  Referral Program  </span></Link>
         
          </li>
          <li>
          <figure>
            <img
              src={iconAffiliate}
              alt=""
           
            />
          </figure>
          <a href="/affilate" className="text-decoration-none" target="_blank"> <span>  Affiliate  </span></a>
         
          </li>
     
   
          </ul>
    <hr role="separator" aria-orientation="horizontal" class=" v-divider theme--light"></hr>
    <span class="mobile-drawer-itemgroup-title">Games</span>
          
          <ul>
    
        {DepositWithrawData?.map((item,index) => {
          
          return (
            item?.type!="hotgame" &&  item?.type!="sports" &&
            <li key={index}   onClick={() => navigate('/all-casino/'+item?.type)}  className={`${key == item?.type ? "active" : ""}`}>
              <figure><img src={item?.icons} alt=""/></figure>
              <a className="text-decoration-none"> <span>{lang == "bn" ? item?.titlebn : item?.title}</span></a>
          </li>
          );
        })}
        </ul>
        <hr role="separator" aria-orientation="horizontal" class=" v-divider theme--light"></hr>
        <span class="mobile-drawer-itemgroup-title">{t("Other")}</span>
        <ul>
        
        <li>
          <figure>
            <img
              src={iconFaq}
              alt=""
           
            />
          </figure>
          <Link to="/info/faq" className="text-decoration-none"> <span>  {t("FAQ")}  </span></Link>
         
          </li>
          <li>
          <figure>
            <img
              src={iconDownloadApp}
              alt=""
           
            />
          </figure>
          <Link to="/affilate" className="text-decoration-none"> <span>  {t("DOWNLOAD_APP")} </span></Link>
         
          </li>
          
       
        </ul>
        
        <ul>
        

          <>
          {/* <li className="language-select">
          <div
            className="language-select-div"
            onClick={() => {
             
              setBr();
              
            }}
          >
            <img src={FlagIconBn} alt="" />{" "}
            <p>বাংলা</p> 
          </div>
          <div
            className="language-select-div"
            onClick={() => {
              
                setEn();
              
            }}
          >
            <img src={ FlagIcon } alt="" />{" "}
            <p>English</p>
          </div>
        </li> */}
            
          </></ul>
          {/* <ul className="p-0 m-0 mb-3">
            {sidebarData.length > 0 &&
              sidebarData[0].firstLevel.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item?.link} className="text-decoration-none">
                      {item?.icon} <span>{lang == 'bn' ? item?.menubn : item?.menu}</span>{" "}
                    </Link>
                  </li>
                );
              })}
          </ul> */}
           {/* <button onClick={() => logoutUser()} className="logout-button">
            <BiLogOutCircle className="me-2" />
            {t("Logout")}
          </button> */}
       {/*   <div className="time-zone text-center pt-4">
            <AiOutlineGlobal className="me-2 fs-4" />{" "}
            <span>{t("Time_Zone")}：GMT+6:00</span>
          </div> */}
           
        </div>
        {/* {
          key!="" &&
          <div class="menu-second">
          <ul class="menu-second-ul active">
          {data?.array?.map((res) => {
              return (
                  <li class=""   
                    onClick={() => {
                    if (!isEmpty(user)) {
                      if (data?.key == "exchange") {
                        window.open(res?.link, "_self");
                      } else {
                        if (!isEmpty(user)) {
                          if (res?.gameTypeCheck == "platForm") {
                            launchCasino({
                              platForm: res?.platForm,
                              gameType: res?.gameType,
                              casinoType: res?.casinoType,
                            });
                          } else if (res?.gameType == "egtGame") {
                            launchEGTCasino({
                              platForm: res?.platForm,
                              gameType: res?.gameTypeNew,
                              casinoType: res?.casinoType,
                            });
                          } else if (!isEmpty(res?.gameid)) {
                            launchEGTCasino({
                              platForm: res?.platForm,
                              gameType: res?.gameType,
                              casinoType: res?.casinoType,
                              gameid: res?.gameid,
                            });
                          } else {
                            if (
                              res?.platForm !== "" &&
                              res?.gameType !== "" &&
                              res?.casinoType !== ""
                            ) {
                              launchCasino({
                                platForm: res?.platForm,
                                gameType: res?.gameType,
                                casinoType: res?.casinoType,
                              });
                            }
                          }
                        } else {
                          navigate("/login");
                        }
                      }
                    } else {
                      withoutLogin()
                    }
                  }}>
                  <figure><img src={res?.image} alt=""/></figure><p>{res?.title}</p>
                  </li>
              );
            })}
        </ul>
      </div>
        } */}
       
      </div>
      
    </OutsideClickHandler>
  );
};

export default MenuSidebarNew;
