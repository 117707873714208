import React, { useContext,useEffect } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import FlagIcon from "../assets/imagesNew/india-icon.png";
import FlagIconBn from "../assets/imagesNew/bangladesh-flag-round-circle-icon.png";
import { isEmpty } from "lodash";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import { DepositWithrawData, DepositWithrawDataNew } from "../Utils/constants";

import Registration from "../Auth2/Registration";

const FooterNew = () => {
  const { user, lang, setEn, setBr  } = useContext(AuthContext);

  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className= {!isEmpty(user) ?"footer-new login-footer ":"footer-new"}>
      <ul>
        
        {isEmpty(user) ? (
          <>
          {/* <li className="language-select">
          <div
            className="language-select-div"
            onClick={() => {
              if (lang == "bn") {
                setEn();
              } else {
                setBr();
              }
            }}
          >
            <img src={lang == "bn" ? FlagIcon : FlagIconBn} alt="" />{" "}
            {lang == "bn" ? "INR" : "BDT"} <br />{" "}
            {lang == "bn" ? "English" : "Bangla"}
          </div>
        </li> */}
        <li className="register-button">
              <Link to="/register">{t("Sign_up")}</Link>
            </li>
            <li className="login-button">
              <Link to="/login">{t("Login")}</Link>
            </li>
          </>
        ) : (
          <>
           {DepositWithrawDataNew?.length > 0 &&
          DepositWithrawDataNew?.map((item) => {
            return (
              <li onClick={() => navigate(item?.link)} className="">
              <div className="payment-box footer-payment-box ">
                <figure>
                  <img src={item?.icons} alt="" />
                </figure>
                <span>
                {lang == "bn"
                    ? item?.titlebn
                    : item?.title}
                </span>
              </div>
            </li>
            )})}
           
          </>
        )}
      </ul>
      
    </div>
  );
};

export default FooterNew;
