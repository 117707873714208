import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { apiGet, apiPost } from "../../Utils/apiFetch";
import apiPath from "../../Utils/apiPath";
const MainBanner = () => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplay: true,
    arrows: true,
    slidesToShow:1,
    slidesToScroll: 1,
  };
  const [data, setData] = useState([]);
  const getData = async () => {
    const { status, data } = await apiPost(apiPath.getBanner, {
      type: "home_top",
    });
    if (status == 200) {
      if (data?.success) {
        setData(data?.results?.banners);
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="main-banner">
      {" "}
      <Slider {...settings}>
        {data?.length > 0 &&
          data?.map((item) => {
            return (
              <div className="slider-items-banner">
                <figure className="mb-0">
                  <img
                    // style={{
                    //   width: "250px",
                    //   height: "113px",
                    // }}
                    src={process.env.REACT_APP_API_BASE_URL + item?.banner_path}
                    alt=""
                  />
                </figure>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default MainBanner;
