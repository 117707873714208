import React, { useContext,useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import appdownloadicon from "../assets/images/header-appdownload-icon.svg"
import headerserviceicon from "../assets/images/header-service-icon.svg"
import { isEmpty } from "lodash";
import AuthContext from "../context/AuthContext";
import { BsList } from "react-icons/bs";
import downloadapp from "../assets/images/download-app.png";
import crose from "../assets/images/cross-black.svg";
import luckye888Apk from "../assets/images/luckye888.apk";
import {
  MdArrowBackIosNew,
  MdKeyboardArrowLeft,
  MdOutlineSubject,
} from "react-icons/md";
import MenuSidebar from "./MenuSidebar";
import MenuSidebarNew from "./MenuSidebarNew";
import { HiOutlineRefresh } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import FlagIcon from "../assets/imagesNew/india-icon.png";
import FlagIconBn from "../assets/imagesNew/bangladesh-flag-round-circle-icon.png";
import logoutImg from "../assets/images/logout.svg";

import {
  DepositWithrawData,
  DepositWithrawDataArray,
} from "../Utils/constants";
const HeaderNew = () => {
  const {
    user,
    handleShowSidebar,
    showSidebar,
    amounutRefresh,
    refreshAmountLoader,
    userCoins,
    loginUser, lang, setEn, setBr ,logoutUser
  } = useContext(AuthContext);
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [downloadBar, setShowDownloadBar] = useState(true);
  const [key, setKey] = useState("");
  return (
    <>
    {
      downloadBar && 
      <div class="download-bar">
        <img width="15" height="15" src={crose} alt="download" class="mr-2 crose-icon" onClick={() => setShowDownloadBar(false)}/>
          <img width="32" height="32" src={downloadapp} alt="download" class="mr-2 c-radis" />
            <div class="mr-1 col">
              <b>{t("DOWNLOAD_APP")} <br/>{t("VERSION_NOW")}</b>
            </div>
            <button data-v-3b7d3367="" type="button" class="pa-2 download-bar-button subtitle-1">
              <a class="v-btn__content" href={luckye888Apk} download="luckye888" target='_blank'>DOWNLOAD</a>
              </button>
    </div>
    }
      <div className={downloadBar?"login-header-new download-bar-height":"login-header-new"}>
        <div className="login-reg-wrapper">
        <button
                className="bg-transparent border-0 text-white"
                onClick={() => handleShowSidebar()}
              >
                <BsList className="fs-4" />
              </button>
          <figure onClick={() => navigate('/')}>
          {/* {isEmpty(user) ? (
            <img
              style={{
                height: "50px",
              }}
              src={logo}
              alt=""
            />):  */}
            <img
            style={{
              height: "36px",
            }}
            src={logo}
            alt=""
          />
          {/* } */}
          </figure>

          <div class="d-flex align-items-center justify-content-end w-100">
          {isEmpty(user) ? (
           
              <div class="desktop-log-reg">
                <button type="button" class="v-btn__content2" onClick={()=>navigate("/login")} >Login</button>
                <button type="button" class="v-btn__content1" onClick={()=>navigate("/register")}>Register Now</button>
            </div>
      ):( <div className="align-items-lg-start for-ref after-login">
      {refreshAmountLoader ? (
        <div class="loader-outer">
          <span
            class="loader-inner-icon"
            style={{ animationDelay: "0s" }}
          ></span>
          <span
            class="loader-inner-icon"
            style={{ animationDelay: ".1s" }}
          ></span>
          <span
            class="loader-inner-icon"
            style={{ animationDelay: ".2s" }}
          ></span>
          <span
            class="loader-inner-icon"
            style={{ animationDelay: ".3s" }}
          ></span>
          <span
            class="loader-inner-icon"
            style={{ animationDelay: ".4s" }}
          ></span>
          <span
            class="loader-inner-icon"
            style={{ animationDelay: ".5s" }}
          ></span>
          <span
            class="loader-inner-icon"
            style={{ animationDelay: ".6s" }}
          ></span>
          <span
            class="loader-inner-icon"
            style={{ animationDelay: ".7s" }}
          ></span>
        </div>
      ) : (
        <>
        <div className="header-outer">
          <div className="paise" style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "10px" }}>
              <span>{user?.user?.username}</span>
              <div className="d-flex align-items-center">
                <strong className="header-font-size">
                ৳{" "}
                  <span style={{ marginLeft: "3px", color: "white" }}>
                    {userCoins?.balance?.toFixed()}
                  </span>
                </strong>
                <strong className="header-font-size">
                  Exp{" "}
                  <span
                    className="text-white"
                    style={{ marginLeft: "3px", fontWeight: "700" }}
                  >
                    ({userCoins?.exp?.toFixed()})
                  </span>
                </strong>
              </div>
            </div>
            <div onClick={amounutRefresh}>
              <HiOutlineRefresh
                size={20}
                className={refreshAmountLoader ? "refresh-icon" : ""}
              />
            </div>
          </div>
          <div class="desktop-log-reg">
                <button type="button" class="v-btn__content2 deposite-d" onClick={()=>navigate("/deposit")} >+</button>
               
            </div>
        </div>
        <div class="desktop-log-reg">
        <span className="v-btn__content3" onClick={() => logoutUser()} title="Logout"><img src={logoutImg}/></span>
       
    </div>
    </>
      )}
    </div> )}
          <div
            className="language-select-div"
            onClick={() => {
              if (lang == "bn") {
                setEn();
              } else {
                setBr();
              }
            }}
          >
            <img src={lang == "bn" ? FlagIcon : FlagIconBn} alt="" />{" "}
            {/* {lang == "bn" ? "INR" : "BDT"} <br />{" "} */}
            {lang == "bn" ? "English" : "Bangla"}
          </div> 

          </div>
          {isEmpty(user) ? (
            <>
       
            <figcaption>
              {/* <Link to="/login">Log In /</Link> */}
              {/* <Link to="/login">{t("Login_Registration")}</Link> */}
            </figcaption>
            </>
          ) : (
            <>
             
              {/* <button
                className="bg-transparent border-0 text-white"
                onClick={() => handleShowSidebar()}
              >
                <MdOutlineSubject className="fs-4" />
              </button> */}
            </>
            // <div
            //   style={{
            //     display: "flex",
            //     justifyContent: "space-between",
            //     alignItems: "center",
            //     width: "100%",
            //   }}
            // >
            //   {" "}
            //   <figcaption>+{user?.user?.username}</figcaption>
            //   <button
            //     className="bg-transparent border-0 text-white"
            //     onClick={() => handleShowSidebar()}
            //   >
            //     <MdOutlineSubject className="fs-4" />
            //   </button>
            // </div>
          )}
          {/* <div className="header-right-btn-group">
          
            <a href={}  download="lucky888" target='_blank'><img src={appdownloadicon} /><span>App</span></a>
            <a href="#"><img src={headerserviceicon}/><span >Help</span></a>
            </div> */}
        </div>
        <ul className="desktop-menu">
        <li className="active">
         
          <Link to="/promotions" className="text-decoration-none"> <span> Promotions </span></Link>
         
          </li>
          <li>
        
          <Link to="/refer" className="text-decoration-none"> <span>  Referral Program  </span></Link>
         
          </li>
          <li>
         
          <Link to="/affilate" className="text-decoration-none"> <span>  Affiliate  </span></Link>
         
          </li>
     
          {DepositWithrawData?.map((item,index) => {
          
          return (
            item?.type!="hotgame" &&  item?.type!="sports" &&
            <li key={index}   onClick={() => navigate('/all-casino/'+item?.type)}  className={`${key == item?.type ? "active" : ""}`}>
             
              <a className="text-decoration-none"> <span>{lang == "bn" ? item?.titlebn : item?.title}</span></a>
          </li>
          );
        })}
          </ul> 
        {showSidebar && (
          <MenuSidebarNew
            cancelMenu={handleShowSidebar}
            showSidebar={showSidebar}
          />
        )}

        {showSidebar && (
          <div className="overlay" onClick={() => handleShowSidebar()}></div>
        )}
      </div>
    </>
  );
};

export default HeaderNew;
