import React, { useContext, useState } from "react";
import LayoutNew from "../components/shared/LayoutNew";
import AuthContext from "../context/AuthContext";
import CurrentNews from "../components/CurrentNews";
import GameSlider from "../components/GameSlider";
import FooterSection from "../components/FooterSection";
import MainBanner from "./slider/MainBanner";
import { useTranslation } from "react-i18next";

import referralBanner from "../assets/images/referral-banner.webp";
import downloadnowbanner from "../assets/images/downloadnow_appbanner.webp";

import downloadnowbannerD from "../assets/images/bb88_downloadnow_appbanner_desktop.webp";
import BB88_mobile from "../assets/images/BB88_mobile.webp";
import BB88_desktop from "../assets/images/BB88_desktop.webp";

import iconWithdrawal from "../assets/images/tab-icon/mobileBottomNavigation/account-withdrawal.svg";
import iconReferral from "../assets/images/tab-icon/mobileBottomNavigation/acount-referral.svg";
import { useNavigate,Link } from "react-router-dom";
import { isEmpty } from "lodash";
import luckye888Apk from "../assets/images/luckye888.apk";
import ReactPlayer from "react-player";

const HomeNew = () => {
  const { t } = useTranslation();

  const {messagelist, setAnnouncement,user,userCoins } =
    useContext(AuthContext);
  const [show, setShow] = useState(false);
  return (
    <LayoutNew>
      <MainBanner />
      <CurrentNews
        message={messagelist}
        setAnnouncement={setAnnouncement}
      />
      <div className="main">
      {!isEmpty(user) ? (
      <div class="member-balance">
        <div class="balance-details hidden-md-and-up">
          <div class="row no-gutters"><div class="pa-2 col-4">
            <div class="row no-gutters">
              <div class="col">{user?.user?.username}</div>
            </div><div class="row no-gutters">
              <div class="col">৳ {userCoins?.balance?.toFixed()}</div>
              </div>
              </div>
              <div class="d-flex align-center col col-8 justify-content-end">
                <div class="d-flex justify-space-around">
                  <div>
                      <Link to="/refer" class="nav-item v-card v-card--link v-sheet theme--dark" tabindex="0">
                        <img src={iconReferral} class="img-nav-item"/></Link>
                        <span class="nav-item-span">{t("Referral_Menu")}</span>
                    </div>
                      <div>
                          <Link to="/withdraw" class="nav-item v-card v-card--link v-sheet theme--dark" tabindex="0">
                            <img src={iconWithdrawal} class="img-nav-item"/></Link>
                            <span class="nav-item-span">{t("Withdrawal")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>):("")}
        <GameSlider />
        <div className="ankita_banner">
          <img src={BB88_mobile} className="mobile-ankita"/>
          <img src={BB88_desktop} className="desktop-ankita"/>
        </div>
        <div className="common-slider">
        <div className="heading-block">
          <h5>{t("DOWNLOAD_APP")}</h5>
         
        </div>

        <div className="common-slider-box">
        <figure className="mb-0">
        <a class="" href={luckye888Apk} download="lucky888" target='_blank'>
          <img src={downloadnowbanner} alt="" className="d-m"/>
          <img src={downloadnowbannerD} alt="" className="d-d"/>
          </a>  
          </figure>
          </div>
          
          </div>

          <div className="common-slider">
        <div className="heading-block">
          <h5>{t("Referral_Menu")}</h5>
          
        </div>

        <div className="common-slider-box">
        <figure className="mb-0">
                    <img src={referralBanner} alt="" className="d-m"/>
                    <img src={referralBanner} alt="" className="d-d"/>
                  </figure>
          </div></div>

          <ReactPlayer
          muted={false}
          controls={false}
          playsinline={true}
          playing={false}
          width="100%"
          height="auto"
          url={`https://youtu.be/8t5wnqNfxtI`}
          config={{
            file: {
              attributes: {
                preload: "none",
                //forceHLS: true,
              },
              hlsOptions: {
                //autoStartLoad: false,
                startLevel: 3,
              },
            },
          }}
        />
        
        <FooterSection />
      </div>
    </LayoutNew>
  );
};

export default HomeNew;
