import React from "react";
import { AiOutlineAudio } from "react-icons/ai";
import { HiOutlineSpeakerphone } from "react-icons/hi";
const CurrentNews = ({ message, setAnnouncement }) => {
  return (
    <>
      <div
        onClick={() => {
          if (message?.length > 0) {
            setAnnouncement(true);
          }
        }}
        className="marquee-notification d-flex"
      >
        {/* <div className="audio-trakcer">
          <HiOutlineSpeakerphone />
        </div> */}
       
        {message?.length > 0 && (
          <marquee direction="left">
            {message?.map((res) => {
              return <strong>{res?.message}</strong>;
            })}
          </marquee>
        )}
      </div>
    </>
  );
};

export default CurrentNews;
