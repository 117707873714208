import React, { useContext, useEffect, useRef, useState } from "react";
import LayoutNew from "../components/shared/LayoutNew";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { Button, Form, InputGroup } from "react-bootstrap";
import { preventMaxInput, validationRules } from "../Utils/constants";
import { isEmpty } from "lodash";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { IoMdArrowBack } from "react-icons/io";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { toast } from "react-toastify";
import MainBanner from "./slider/MainBanner";
import FooterSection from "../components/FooterSection";
import AuthContext from "../context/AuthContext";
import logo from "../assets/images/logo.png";
import ReactPlayer from "react-player";

const AfiliateLink = () => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { setAffiliate, affiliate } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [conPassToggle, setConPassTogle] = useState(false);
  const { t } = useTranslation();
  const { search } = useLocation();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    shouldFocusError: true,
  });
  useEffect(() => {
    if (!isEmpty(watch("confirmPassword"))) {
      if (watch("password")) {
        trigger("confirmPassword");
      }
    }
  }, [watch("password")]);
  const onSubmit = async (body) => {
    let hostname = window.location.hostname;
    hostname = hostname.replace(/^www\./, "");
    hostname = hostname.replace(/^ag\./, "");
    const { status, data } = await apiPost(apiPath.affiliate, {
      whatsappNumber: body?.mobile,
      username: body?.username,
      password: body?.password,
      confirmPassword: body?.confirmPassword,
      referral_code: body?.referral_code || "",
      website: hostname || "SABAEXCH",
    });
    if (status === 200) {
      if (data.success) {
        toast.success(data?.message);
        setAffiliate(data?.results?.adminuser?.referalCode);
        navigate(
          `/affilates?referral_code=${data?.results?.adminuser?.referalCode}`
        );
      } else {
        toast.error(data?.message);
      }
    } else {
      toast.error(data?.message);
    }
  };

  useEffect(() => {
    if (!isEmpty(search)) {
      if (search?.split("=")[0] == "?referral_code") {
        setValue("referral_code", search?.split("=")[1]);
      }
    }
  }, [search]);
  return (
   
      <div>
        {/* <div class="p-title title-box">
          <IoMdArrowBack onClick={() => navigate(-1)} size={30} />
          <div class="title w-100">{t("Affiliate_Agent")}</div>
        </div>{" "} */}

       
        <div className="affiliate-page">
        <header style={{backgroundColor:`#fff`,padding: `10px 0px`}}>
          <div className="d-flex align-items-center justify-content-between pe-3">
            <a href="/"> <img style={{width:`235px`}}
                src={logo}
                alt=""
              /></a>
              <div>
              <a href="https://ag.luckye888.win/" target="_blank" className="af-login"> Login</a>
              <a href="#afilate-form" className="af-login">Signup</a>
              </div>
          </div>
          
        </header>
        <MainBanner />
        <div class="et_pb_section et_pb_section_1 et_pb_with_background et_section_regular snipcss-PVUTn">
        <div class="et_pb_row et_pb_row_0">
            <div
                class="et_pb_column et_pb_column_4_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough et-last-child">
                <div class="et_pb_module et_pb_text et_pb_text_0  et_pb_text_align_left et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <h2>
                            কিভাবে lucky888 এজেন্ট অ্যাফিলিয়েট হবেন?
                        </h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="et_pb_with_border et_pb_row et_pb_row_1">
            <div class="et_pb_column et_pb_column_1_3 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough">
                <div class="et_pb_module et_pb_image et_pb_image_0 et_pb_image_sticky et_multi_view__hover_selector">
                    <span class="et_pb_image_wrap ">
                        <img loading="lazy" decoding="async" width="149" height="149" src="../assets/../assets/images/STEP-1-ICON.png"
                            alt="" title="STEP 1 ICON" class="wp-image-1030"/>
                    </span>
                </div>
                <div class="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_center et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <p>
                            নীচের বোতামে ক্লিক করুন এবং Google ফর্মটি পূরণ করুন
                        </p>
                    </div>
                </div>
            </div>
            <div class="et_pb_column et_pb_column_1_3 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough">
                <div class="et_pb_module et_pb_image et_pb_image_1 et_pb_image_sticky et_multi_view__hover_selector">
                    <span class="et_pb_image_wrap ">
                        <img loading="lazy" decoding="async" width="149" height="149" src="../assets/images//STEP-2-ICON.png"
                            alt="" title="STEP 2 ICON" class="wp-image-816"/>
                    </span>
                </div>
                <div class="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_center et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <p>
                            আপনি Google ফর্ম জমা দেওয়ার পরে lucky888 পরিচালকরা আপনার সাথে যোগাযোগ করবেন
                        </p>
                        <p>
                            <span>
                                অনুগ্রহ করে নিশ্চিত করুন যে আপনার Whatsapp এবং টেলিগ্রাম সক্রিয় আছে যাতে lucky888
                                ম্যানেজাররা আপনার সাথে যোগাযোগ করতে পারেন।
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div
                class="et_pb_column et_pb_column_1_3 et_pb_column_3  et_pb_css_mix_blend_mode_passthrough et-last-child">
                <div class="et_pb_module et_pb_image et_pb_image_2 et_pb_image_sticky et_multi_view__hover_selector">
                    <span class="et_pb_image_wrap ">
                        <img loading="lazy" decoding="async" width="149" height="149" src="../assets/images//STEP-3-ICON.png"
                            alt="" title="STEP 3 ICON" class="wp-image-817"/>
                    </span>
                </div>
                <div class="et_pb_module et_pb_text et_pb_text_3  et_pb_text_align_center et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <p>
                            lucky888 এজেন্ট অ্যাকাউন্ট পাওয়ার পর, আপনি এখন খেলোয়াড়দের আমন্ত্রণ জানাতে এবং উপার্জন শুরু
                            করতে পারেন!
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="et_pb_row et_pb_row_2">
            <div
                class="et_pb_column et_pb_column_4_4 et_pb_column_4  et_pb_css_mix_blend_mode_passthrough et-last-child">
                <div
                    class="et_pb_button_module_wrapper et_pb_button_0_wrapper et_pb_button_alignment_center et_pb_module ">
                    <a id="agent-registration-link" class="et_pb_button et_pb_button_0 et_pb_bg_layout_light" href=""
                        target="_blank">
                        জয়েন করতেএখানে ক্লিক করুন!
                    </a>
                </div>
                <div class="et_pb_module et_pb_text et_pb_text_4  et_pb_text_align_center et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <p class="p1">
                            ** একটি এজেন্ট একাউন্ট শুধুমাত্র ঐ দেশের পরিষেবা দিতে পারে যে সাইটে একাউন্টটি নিবন্ধিত
                            হয়েছে। যদি কোন এজেন্ট অন্য দেশে পরিষেবা দিতে চায়, তাহলে অনুগ্রহ করে নিবন্ধনের পর আমাদের
                            ম্যানেজারদের সাথে যোগাযোগ করবেন।
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="et_pb_section et_pb_section_2 et_pb_with_background et_section_regular snipcss-w73Q5">
        <div class="et_pb_row et_pb_row_3">
            <div
                class="et_pb_column et_pb_column_4_4 et_pb_column_5  et_pb_css_mix_blend_mode_passthrough et-last-child">
                <div class="et_pb_module et_pb_text et_pb_text_5  et_pb_text_align_center et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <h2>
                            lucky888 কমিশন গণনা
                        </h2>
                    </div>
                </div>
                <div class="et_pb_module et_pb_text et_pb_text_6  et_pb_text_align_center et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <p>
                            <span id="style-CYcgO" class="style-CYcgO">
                                50% আজীবন কমিশন
                            </span>
                        </p>
                    </div>
                </div>
                <div class="et_pb_module et_pb_text et_pb_text_7  et_pb_text_align_center et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <span id="style-8I4TX" class="style-8I4TX">
                            আপনার কঠোর পরিশ্রম মহান পুরষ্কারের যোগ্য, এবং এখন আপনি অক্টোবর থেকে&nbsp;
                        </span>
                        <span id="style-QKJhY" class="style-QKJhY">
                            শুরু হওয়া আপনার খেলোয়াড়দের জীবনকালের জন্য 50% পর্যন্ত একটি উল্লেখযোগ্য
                        </span>
                        <span id="style-baHpY" class="style-baHpY">
                            কমিশন উপার্জন করতে পারেন!
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="et_pb_row et_pb_row_4">
            <div
                class="et_pb_column et_pb_column_4_4 et_pb_column_6  et_pb_css_mix_blend_mode_passthrough et-last-child">
                <div class="et_pb_module et_pb_image et_pb_image_3 et_pb_image_sticky">
                    <span class="et_pb_image_wrap ">
                        <img fetchpriority="high" decoding="async" width="1920" height="499"
                            src="../assets/images/BB88-NewCommission-Calculation-2.png" alt=""
                            title="BB88-NewCommission-Calculation-2" class="wp-image-1783"/>
                    </span>
                </div>
            </div>
        </div>
        <div class="et_pb_row et_pb_row_5">
            <div
                class="et_pb_column et_pb_column_4_4 et_pb_column_7  et_pb_css_mix_blend_mode_passthrough et-last-child">
                <div class="et_pb_module et_pb_text et_pb_text_8  et_pb_text_align_left et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <p>
                            উদাহরণ:
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="et_pb_row et_pb_row_6">
            <div
                class="et_pb_column et_pb_column_4_4 et_pb_column_8  et_pb_css_mix_blend_mode_passthrough et-last-child">
                <div class="et_pb_module et_pb_image et_pb_image_4 et_pb_image_sticky">
                    <span class="et_pb_image_wrap ">
                        <img loading="lazy" decoding="async" width="1920" height="1068"
                            src="../assets/images/BB88-NewCommission-example-3.png" alt="" title="BB88-NewCommission-example-3"
                            class="wp-image-1788"/>
                    </span>
                </div>
            </div>
        </div>
        <div class="et_pb_row et_pb_row_7">
            <div
                class="et_pb_column et_pb_column_4_4 et_pb_column_9  et_pb_css_mix_blend_mode_passthrough et-last-child">
                <div class="et_pb_module et_pb_text et_pb_text_9  et_pb_text_align_left et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <p class="p1">
                            ন্যূনতম আমানত: সক্রিয় খেলোয়াড় প্রতি BDT1000
                        </p>
                        <p class="p1">
                            সক্রিয় খেলোয়াড়: খেলোয়াড় যারা আমানত এবং বাজি রেখেছেন
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="et_pb_section et_pb_section_3 et_pb_with_background et_section_regular snipcss-H4oYk">
        <div class="et_pb_row et_pb_row_8">
            <div
                class="et_pb_column et_pb_column_4_4 et_pb_column_10  et_pb_css_mix_blend_mode_passthrough et-last-child">
                <div
                    class="et_pb_module et_pb_text et_pb_text_10 et_pb_text_align_left et_pb_text_align_center-tablet et_pb_bg_layout_light et_multi_view__hover_selector">
                    <div class="et_pb_text_inner">
                        <p>
                            <span id="style-lMhHm" class="style-lMhHm">
                                <strong>
                                    lucky888 ব্যাকআপ লিঙ্ক:
                                </strong>
                            </span>
                        </p>
                    </div>
                </div>
                <div
                    class="et_pb_module et_pb_text et_pb_text_11  et_pb_text_align_left et_pb_text_align_center-tablet et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <p id="style-mLRA3" class="style-mLRA3">
                            <span id="style-T6aow" class="style-T6aow">
                                lucky888 জানেন যে একটি ওয়েবসাইট ডাউন হয়ে গেলে কতটা হতাশাজনক হতে পারে। আমরা সমস্যা
                                সমাধানের জন্য কাজ করার সময় আমাদের সাইট অ্যাক্সেস করতে এই বিকল্প লিঙ্কগুলি ব্যবহার করুন৷
                            </span>
                        </p>
                    </div>
                </div>
                <div
                    class="et_pb_button_module_wrapper et_pb_button_1_wrapper et_pb_button_alignment_left et_pb_button_alignment_tablet_center et_pb_button_alignment_phone_left et_pb_module ">
                    <a class="et_pb_button et_pb_button_1 et_pb_bg_layout_light" href="http://www.lucky888bdt.com"
                        data-icon="9">
                        WWW.lucky888BDT.COM
                    </a>
                </div>
                <div
                    class="et_pb_button_module_wrapper et_pb_button_2_wrapper et_pb_button_alignment_left et_pb_button_alignment_tablet_center et_pb_button_alignment_phone_center et_pb_module ">
                    <a class="et_pb_button et_pb_button_2 et_pb_bg_layout_light" href="https://www.lucky888.app"
                        data-icon="9">
                        WWW.lucky888.APP
                    </a>
                </div>
                <div
                    class="et_pb_button_module_wrapper et_pb_button_3_wrapper et_pb_button_alignment_left et_pb_button_alignment_tablet_center et_pb_button_alignment_phone_center et_pb_module ">
                    <a class="et_pb_button et_pb_button_3 et_pb_bg_layout_light" href="http://WWW.lucky888A.COM"
                        data-icon="9">
                        WWW.lucky888A.COM
                    </a>
                </div>
                <div
                    class="et_pb_button_module_wrapper et_pb_button_4_wrapper et_pb_button_alignment_left et_pb_button_alignment_tablet_center et_pb_button_alignment_phone_center et_pb_module ">
                    <a class="et_pb_button et_pb_button_4 et_pb_bg_layout_light" href="http://WWW.lucky888B.COM"
                        data-icon="9">
                        WWW.lucky888B.COM
                    </a>
                </div>
                <div
                    class="et_pb_button_module_wrapper et_pb_button_5_wrapper et_pb_button_alignment_left et_pb_button_alignment_tablet_center et_pb_button_alignment_phone_center et_pb_module ">
                    <a class="et_pb_button et_pb_button_5 et_pb_bg_layout_light" href="http://WWW.lucky888C.COM"
                        data-icon="9">
                        WWW.lucky888C.COM
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="et_pb_section et_pb_section_7 et_pb_with_background et_section_regular snipcss-Uqdrl">
        <div class="et_pb_row et_pb_row_11 et_pb_equal_columns et_pb_gutters3">
            <div class="et-cl-new et_pb_column et_pb_column_1_3 et_pb_column_13  et_pb_css_mix_blend_mode_passthrough">
                <div class="et_pb_module et_pb_text et_pb_text_23  et_pb_text_align_center et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <h1>
                            <img loading="lazy" decoding="async" src="../assets/images/trusted-300x300.png" width="80" height="80"
                                alt="" class="wp-image-119 alignnone size-medium"/>
                        </h1>
                        <h1>
                            বিশ্বস্ত ব্র্যান্ড
                        </h1>
                        <p>
                            <span>
                                lucky888 হল প্রিমিয়াম ক্রিকেট এক্সচেঞ্জ এবং 100 টিরও বেশি লাইভ ক্যাসিনো, স্লট এবং
                                ভার্চুয়াল গেম সহ বাংলাদেশের বৃহত্তম অনলাইন ক্যাসিনো প্রদানকারী!
                            </span>
                        </p>
                    </div>
                </div>
                <div class="et_pb_module et_pb_text et_pb_text_24  et_pb_text_align_center et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <h1>
                            <img loading="lazy" decoding="async" src="../assets/images/fair-300x300.png" width="80" height="80"
                                alt="" class="wp-image-151 alignnone size-medium"/>
                        </h1>
                        <h1>
                            <span>
                                স্বচ্ছতা
                            </span>
                        </h1>
                        <p>
                            <span>
                                দৈনিক ডেটা এবং কমিশন ট্র্যাক করতে এজেন্টদের জন্য সহজে অ্যাক্সেসযোগ্য ব্যাকএন্ড
                                সফ্টওয়্যার
                            </span>
                        </p>
                    </div>
                </div>
                <div class="et_pb_module et_pb_text et_pb_text_25  et_pb_text_align_center et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <h1>
                            <img loading="lazy" decoding="async" src="../assets/images/transparency-300x300.png" width="80"
                                height="80" alt="" class="wp-image-118 alignnone size-medium"/>
                        </h1>
                        <h1>
                            <span>
                                ফ্রি একাউন্ট
                            </span>
                        </h1>
                        <p>
                            কোন বিনিয়োগ প্রয়োজন নেই! যে কেউ নিবন্ধন করতে এবং বিনামূল্যে যোগদান করতে পারেন!
                        </p>
                    </div>
                </div>
            </div>
            <div class="et-cl-new et_pb_column et_pb_column_1_3 et_pb_column_14  et_pb_css_mix_blend_mode_passthrough">
                <div class="et_pb_module et_pb_text et_pb_text_26  et_pb_text_align_center et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <h1>
                            <img loading="lazy" decoding="async" src="../assets/images//customerservice-300x300.png" width="80"
                                height="80" alt="" class="wp-image-165 alignnone size-medium"/>
                        </h1>
                        <h1>
                            <span>
                                বাংলা ভাষী সমর্থন
                            </span>
                        </h1>
                        <p>
                            <code class="text-entity-code clickable" role="textbox" tabindex="0">
                    আমাদের গ্রাহক পরিষেবার সাথে বাংলায় কথা বলুন, আমাদের পেশাদার গ্রাহক পরিষেবাতে স্বাচ্ছন্দ্য বোধ ক
                  </code>
                            <span>
                                রবেন
                            </span>
                        </p>
                    </div>
                </div>
                <div class="et_pb_module et_pb_text et_pb_text_27  et_pb_text_align_center et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <h1>
                            <img loading="lazy" decoding="async" src="../assets/images/highestpercentage-300x300.png" width="80"
                                height="80" alt="" class="wp-image-114 alignnone size-medium"/>
                        </h1>
                        <h1>
                            সর্বোচ্চ এজেন্ট কমিশন
                        </h1>
                        <p>
                            lucky888 এজেন্ট বাজারে সর্বোচ্চ কমিশন দেয়! সর্বদা আমাদের সাথে একটি বিজয়ী হতে!
                        </p>
                    </div>
                </div>
                <div class="et_pb_module et_pb_text et_pb_text_28  et_pb_text_align_center et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <h1>
                            <img loading="lazy" decoding="async" src="../assets/images/fastwithdrawals-300x300.png" width="80"
                                height="80" alt="" class="wp-image-152 alignnone size-medium"/>
                        </h1>
                        <h1>
                            <span>
                                নিশ্চিত দ্রুত উত্তোলন
                            </span>
                        </h1>
                        <p>
                            <span>
                                আপনার সদস্যরা আমাদের দ্রুত এবং 100% গ্যারান্টিযুক্ত উত্তোলনের সাথে ক্রমাগত খুশি হবেন
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div
                class="et-cl-new et_pb_column et_pb_column_1_3 et_pb_column_15  et_pb_css_mix_blend_mode_passthrough et-last-child">
                <div class="et_pb_module et_pb_text et_pb_text_29  et_pb_text_align_center et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <h1>
                            <img loading="lazy" decoding="async" src="../assets/images/marketingsupport-300x300.png" width="80"
                                height="80" alt="" class="wp-image-115 alignnone size-medium"/>
                        </h1>
                        <h1>
                            <span>
                                প্রচার সামগ্রী
                            </span>
                        </h1>
                        <p>
                            <span>
                                সমস্ত lucky888 এজেন্টদের প্রচারের জন্য এজেন্টদের সাহায্য করার জন্য উপকরণ এবং সর্বশেষ বিপণন
                                প্রমোশন সরবরাহ করা হবে
                            </span>
                        </p>
                    </div>
                </div>
                <div class="et_pb_module et_pb_text et_pb_text_30  et_pb_text_align_center et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <h1>
                            <img loading="lazy" decoding="async" src="../assets/images/agentmanager-300x300.png" width="80"
                                height="80" alt="" class="wp-image-113 alignnone size-medium"/>
                        </h1>
                        <h1>
                            এজেন্ট কমিউনিটি ম্যানেজার
                        </h1>
                        <p>
                            যে কোনো ধরনের সহায়তার জন্য ডেডিকেটেড এজেন্ট কমিউনিটি ম্যানেজার রয়েছে
                        </p>
                    </div>
                </div>
                <div class="et_pb_module et_pb_text et_pb_text_31  et_pb_text_align_center et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <h1>
                            <img loading="lazy" decoding="async" src="../assets/images/premium-300x300.png" width="80" height="80"
                                alt="" class="wp-image-116 alignnone size-medium"/>
                        </h1>
                        <h1>
                            প্রিমিয়াম প্ল্যাটফর্ম
                        </h1>
                        <p>
                            lucky888 হল একটি প্রিমিয়াম প্ল্যাটফর্ম যা একটি সাধারণ ইউজার ইন্টারফেস সহ সকল সদস্যদের জন্য
                            ব্যবহার করা সহজ
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="et_pb_section et_pb_section_8 et_pb_with_background et_section_regular snipcss-6IBUh">
        <div class="et_pb_row et_pb_row_12">
            <div class="et_pb_column et_pb_column_1_2 et_pb_column_16  et_pb_css_mix_blend_mode_passthrough">
                <div class="et_pb_module et_pb_text et_pb_text_32  et_pb_text_align_left et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <h2>
                            জয়েন lucky888
                        </h2>
                    </div>
                </div>
                <div
                    class="et_pb_module et_pb_text et_pb_text_33 et_pb_text_align_left et_pb_text_align_center-phone et_pb_bg_layout_light et_multi_view__hover_selector">
                    <div class="et_pb_text_inner">
                        এক্সক্লুসিভ অফিসিয়াল এজেন্ট অ্যাফিলিয়েট প্রোগ্রাম
                        <br/>
                        এবং আরো টাকা উপার্জন!
                    </div>
                </div>
                <div
                    class="et_pb_module et_pb_text et_pb_text_34  et_pb_text_align_left et_pb_text_align_center-phone et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        বাংলাদেশ নম্বর 1 ক্রিকেট এক্সচেঞ্জ
                        <br/>
                        এবং বেটিং প্ল্যাটফর্ম
                    </div>
                </div>
                <div
                    class="et_pb_module et_pb_text et_pb_text_35 et_pb_text_align_left et_pb_text_align_center-phone et_pb_bg_layout_light et_multi_view__hover_selector">
                    <div class="et_pb_text_inner et_multi_view_swapped">
                        আজীবন
                        <span id="style-5EyYt" class="style-5EyYt">
                            50%
                        </span>
                        পর্যন্ত কমিশন উপার্জন করুন! সদস্য আনুন! আরও অর্থ উপার্জন করুন!
                    </div>
                </div>
                {/* <div
                    class="et_pb_button_module_wrapper et_pb_button_11_wrapper et_pb_button_alignment_left et_pb_button_alignment_phone_center et_pb_module ">
                    <a id="agent-registration-link" class="et_pb_button et_pb_button_11 et_pb_bg_layout_light" href=""
                        target="_blank" data-icon="9">
                        এখনই বাবু৮৮ এর সাথে যোগ দিন
                    </a>
                </div> */}
            </div>
            <div
                class="et_pb_column et_pb_column_1_2 et_pb_column_17  et_pb_css_mix_blend_mode_passthrough et-last-child et_pb_column_empty">
            </div>
        </div>
    </div>
    <ReactPlayer
          muted={false}
          controls={false}
          playsinline={true}
          playing={false}
          width="100%"
          height="auto"
          url={`https://youtu.be/8t5wnqNfxtI`}
          config={{
            file: {
              attributes: {
                preload: "none",
                //forceHLS: true,
              },
              hlsOptions: {
                //autoStartLoad: false,
                startLevel: 3,
              },
            },
          }}
        />
          <Form className="afilate-form" onSubmit={handleSubmit(onSubmit)} id="afilate-form">
            <div className="mb-3">
              <Form.Group className="form-group pb-1">
                <Form.Label className="mb-0">{t("Username")}</Form.Label>
                <Form.Control
                  className="p-1"
                  autoComplete={false}
                  {...register("username", {
                    required: {
                      value: true,
                      message: t("Please_enter_username"),
                    },
                  })}
                />
              </Form.Group>
              {errors?.username?.message && (
                <div className="text-danger">{errors?.username?.message}</div>
              )}
            </div>
            <div className="mb-3">
              <Form.Group className="form-group">
                <Form.Label>{t("WhatsApp_number")}</Form.Label>
                <Controller
                  className="form-group d-flex"
                  control={control}
                  name="mobile"
                  rules={{
                    required: t("enter_whatsApp_number"),
                    validate: (value) => {
                      let inputValue = value
                        ?.toString()
                        ?.slice(
                          inputRef?.current?.state?.selectedCountry?.countryCode
                            ?.length,
                          value?.length
                        );
                      if (inputValue?.length < 9) {
                        return t("contain_9_digit");
                      } else if (inputValue?.length > 10) {
                        return t("exceed_10_digit");
                      } else {
                        return true;
                      }
                    },
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <PhoneInput
                      {...field}
                      inputExtraProps={{
                        ref,
                        required: true,
                        autoFocus: true,
                      }}
                      ref={inputRef}
                      inputStyle={{
                        width: "100%",
                        height: "38px",
                      }}
                      country={"bd"}
                      enableSearch
                      countryCodeEditable={false}
                    />
                  )}
                />
              </Form.Group>
              {errors?.mobile?.message && (
                <div className="text-danger">{errors?.mobile?.message}</div>
              )}
            </div>
            <div className="mb-3">
              <Form.Group>
                <Form.Label>{t("Password")}</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={!showPassword ? "password" : "text"}
                    placeholder={t("Enter_Password")}
                    maxLength={16}
                    onInput={(e) => preventMaxInput(e)}
                    {...register("password", {
                      required: t("Please_enter_password"),
                      validate: (value) => {
                        if (value === "") {
                          return true;
                        }
                        if (!!value.trim()) {
                          return true;
                        } else {
                          return t("White_spaces_not_allowed");
                        }
                      },
                      pattern: {
                        value: validationRules.newPass,
                        message: t("must_be_8_character_long_new"),
                      },
                      maxLength: {
                        value: 16,
                        message: t("Maximum_length_must_be_16"),
                      },
                    })}
                  />

                  <InputGroup.Text
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: "pointer" }}
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible />
                    ) : (
                      <AiOutlineEye />
                    )}
                  </InputGroup.Text>
                </InputGroup>
                {errors?.password?.message && (
                  <div className="text-danger">{errors?.password?.message}</div>
                )}
              </Form.Group>
            </div>
            <div className="mb-3">
              <Form.Group>
                <Form.Label>{t("Confirm_Password")}</Form.Label>
                <InputGroup>
                  <Form.Control
                    placeholder={t("Enter_confirm_password")}
                    onInput={(e) => preventMaxInput(e)}
                    maxLength={16}
                    type={!conPassToggle ? "password" : "text"}
                    {...register("confirmPassword", {
                      required: {
                        value: true,
                        message: t("Please_enter_confirm_password"),
                      },
                      validate: (value) => {
                        if (value === "") {
                          return true;
                        }
                        if (!isEmpty(watch("password"))) {
                          if (value === watch("password")) {
                            return true;
                          } else {
                            return t("confirm_password_does_not_match");
                          }
                        }
                      },
                      maxLength: {
                        value: 16,
                        message: t("Maximum_length_must_be_16"),
                      },
                    })}
                    onChange={(e) => {
                      setValue("confirmPassword", e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  <InputGroup.Text
                    onClick={() => setConPassTogle(!conPassToggle)}
                    style={{ cursor: "pointer" }}
                  >
                    {conPassToggle ? (
                      <AiOutlineEyeInvisible />
                    ) : (
                      <AiOutlineEye />
                    )}
                  </InputGroup.Text>
                </InputGroup>
                {errors?.confirmPassword?.message && (
                  <div className="text-danger">
                    {errors?.confirmPassword?.message}
                  </div>
                )}
              </Form.Group>
            </div>
            <div className="mb-3">
              <Form.Group className="form-group pb-1">
                <Form.Label className="mb-0">
                  Referral Code (Optional)
                </Form.Label>
                <Form.Control
                  type="text"
                  className="p-1"
                  autoComplete={false}
                  {...register("referral_code")}
                />
              </Form.Group>
            </div>
            <div className="w-100">
              <Button
                style={{ background: "rgb(0 0 0)", border: "none" }}
                className="w-100"
                type="submit"
              >
                {t("Submit")}
              </Button>
            </div>
          </Form>
          
        </div>
      </div>
    
  );
};

export default AfiliateLink;
