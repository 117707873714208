import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LayoutComponent from "../components/shared/Layout";
import { IoIosArrowBack } from "react-icons/io";
import { useForm, Controller } from "react-hook-form";
import { isEmpty } from "lodash";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import OtpInput from "react18-input-otp";
import verfifyImg from "../assets/images/otp-verify.webp";
import AuthContext from "../context/AuthContext";
const ChangePassword = () => {
  const inputRef = useRef(null);
  const { logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [success, setSuccess] = useState({
    check: true,
    message: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    unregister,
    reset,
    watch,
    trigger,
    control,
    setValue,
  } = useForm({});

  useEffect(() => {
    if (!isEmpty(watch("confirmPin"))) {
      if (watch("pin")) {
        trigger("confirmPin");
      }
    }
  }, [watch("pin")]);

  const onSubmit = async (body) => {
    const obj = {
      oldPassword: body?.oldPin,
      newPassword: body?.confirmPin,
    };
    const { status, data } = await apiPost(apiPath.changePassword, obj);
    if (status === 200) {
      if (data.success) {
        logoutUser();
      } else {
        setSuccess({ check: true, message: data?.message });
      }
    } else {
      setSuccess({ check: true, message: data?.message });
    }
  };

  return (
    <div>
      <LayoutComponent page={"change"}>
        <div className="d-flex align-items-center justify-content-start">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              background: "white",
              borderRadius: "50%",
              marginLeft: "8px",
              marginTop: "8px",
            }}
            onClick={() => navigate(-1)}
          >
            <IoIosArrowBack size={30} className="fs-1 p-1 cursor-pointer" />
          </div>
        </div>

        <div className="otp-verfication" style={{ height: "100%" }}>
          <div className="text-center">
            <figure>
              <img src={verfifyImg} alt="" />
            </figure>
            <h4>Change Pin</h4>
            <Form onSubmit={handleSubmit(onSubmit)} className="">
              <Form.Group className="mb-2 d-flex flex-column text-start">
                <Form.Label style={{ fontSize: "14px", fontWeight: "600" }}>
                  Old Pin
                </Form.Label>
                <Controller
                  className="form-group d-flex"
                  control={control}
                  name="oldPin"
                  rules={{
                    required: "Please enter old pin.",
                    validate: (value) => {
                      if (value?.toString().length !== 6) {
                        return "Please enter 6 digit pin";
                      }
                    },
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <OtpInput
                      numInputs={6}
                      {...field}
                      inputExtraProps={{
                        ref,
                        required: true,
                        autoFocus: true,
                      }}
                      ref={inputRef}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      inputStyle={{
                        width: "88%",
                        height: "44px",
                        borderRadius: "7px",
                        border: "1px solid #ced4da",
                      }}
                      separator={<span> </span>}
                    />
                  )}
                />
                {errors?.oldPin?.message && (
                  <div className="text-danger">{errors?.oldPin?.message}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-2 d-flex flex-column text-start">
                <Form.Label style={{ fontSize: "14px", fontWeight: "600" }}>
                  New Pin
                </Form.Label>
                <Controller
                  className="form-group d-flex"
                  control={control}
                  name="pin"
                  rules={{
                    required: "Please enter pin.",
                    validate: (value) => {
                      if (value?.toString().length !== 6) {
                        return "Please enter 6 digit pin";
                      }
                    },
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <OtpInput
                      numInputs={6}
                      {...field}
                      inputExtraProps={{
                        ref,
                        required: true,
                        autoFocus: true,
                      }}
                      ref={inputRef}
                      isInputNum={true}
                      // shouldAutoFocus={true}
                      inputStyle={{
                        width: "88%",
                        height: "44px",
                        borderRadius: "7px",
                        border: "1px solid #ced4da",
                      }}
                      separator={<span> </span>}
                    />
                  )}
                />
                {errors?.pin?.message && (
                  <div className="text-danger">{errors?.pin?.message}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-2 d-flex flex-column text-start">
                <Form.Label style={{ fontSize: "14px", fontWeight: "600" }}>
                  Confirm Pin
                </Form.Label>
                <Controller
                  className="form-group d-flex"
                  control={control}
                  name="confirmPin"
                  rules={{
                    required: "Please enter confirm pin.",
                    validate: (value) => {
                      if (value?.toString().length !== 6) {
                        return "Please enter 6 digit pin";
                      }
                      if (!isEmpty(watch("pin"))) {
                        if (value == watch("pin")) {
                          return true;
                        } else {
                          return "new pin and confirm pin does not match.";
                        }
                      }
                    },
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <OtpInput
                      numInputs={6}
                      {...field}
                      inputExtraProps={{
                        ref,
                        required: true,
                        autoFocus: true,
                      }}
                      ref={inputRef}
                      isInputNum={true}
                      // shouldAutoFocus={true}
                      inputStyle={{
                        width: "88%",
                        height: "44px",
                        borderRadius: "7px",
                        border: "1px solid #ced4da",
                      }}
                      separator={<span> </span>}
                    />
                  )}
                />
                {errors?.confirmPin?.message && (
                  <div className="text-danger">
                    {errors?.confirmPin?.message}
                  </div>
                )}
              </Form.Group>
              <div className="text-center mt-3">
                <Button
                  type="submit"
                  className="theme-btn ms-auto px-5"
                  style={{ fontSize: "14px" }}
                >
                  Change
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default ChangePassword;
